// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: 'HHEO',
  production: false,
  apiUrlDev:
    'https://hheo-gateway-dev.redfield-2067e6a5.uaenorth.azurecontainerapps.io/',
  //apiUrl:'https://hheo-gateway-dev.redfield-2067e6a5.uaenorth.azurecontainerapps.io/',
  //apiUrl: "https://hheo-gateway-qc.wonderfulplant-e6007fd6.uaenorth.azurecontainerapps.io/",
  apiUrl: 'https://hheo-gateway-stg.politewave-7a937208.uaenorth.azurecontainerapps.io/',
  env: 'Development',
  version: '1.0',
  defaultLang: 'en',
};
